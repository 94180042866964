import {inject} from 'vue';
import {PRISMIC_CLIENT} from '~/app/keys';
import type {Client} from '@prismicio/client';
import {predicate} from '@prismicio/client';
import {createError, useSpanWrap} from '#imports';
import type {RedirectDocument} from '@prismicio-types';

export default async function usePrismicRedirect(path: string, lang: string, prismicClient?: Client): Promise<RedirectDocument> {
    const client = prismicClient ?? inject(PRISMIC_CLIENT);
    const predicates = [predicate.at('my.redirect.redirectFrom.url', path)];

    try {
        return await useSpanWrap(() => client!.getFirst<RedirectDocument>({predicates, lang}), {
            httpMethod: 'prismic.get',
            kind: 'prismic',
            name: `[prismic] ${path}`,
        });
    } catch (error) {
        throw createError({message: `Prismic redirect not found for ${path} in ${lang}`, statusCode: 404});
    }
}
