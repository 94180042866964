import {inject} from 'vue';
import {FETCH_LINKS, PRISMIC_CLIENT, USER_LOCALE} from '~/app/keys';
import usePrismicRedirect from '~/modules/redirect/usePrismicRedirect';
import {useLogger, useRoute, useSpanWrap, useNuxtApp, navigateTo} from '#imports';
import {callWithNuxt} from '#app';
import type {PrismicDocument} from '@prismicio/client';
/**
 * Retrieve document from Prismic repository.
 * @param documentType  Document type.
 * @param uid           Document UID.
 */

export default async function usePrismicDocument<T extends PrismicDocument>(documentType: string, uid?: string): Promise<T | undefined | null> {
    const logger = useLogger();
    const client = inject(PRISMIC_CLIENT);
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const locale = inject(USER_LOCALE);
    const lang = locale?.replace('_', '-') ?? 'fr-FR';

    try {
        const getPrismicDocument = () =>
            uid
                ? client!.getByUID<T>(documentType, uid, {lang, fetchLinks: FETCH_LINKS})
                : client!.getSingle<T>(documentType, {lang, fetchLinks: FETCH_LINKS});
        return await useSpanWrap(getPrismicDocument, {httpMethod: 'prismic.get', kind: 'prismic', name: `[prismic] ${documentType}`});
    } catch (_) {
        logger.info(`Could not retrieve document from Prismic with parameters: [${documentType}, ${lang}, ${uid}]. Trying redirection...`);
    }

    // if the document does not exist, check for redirect or throw 404
    const redirect = await callWithNuxt(nuxtApp, usePrismicRedirect, [route.path, lang, client]);
    if (!redirect) {
        return;
    }
    callWithNuxt(nuxtApp, () => navigateTo(redirect.data.redirectTo as string, {redirectCode: 301}));
}
